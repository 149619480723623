import pick from 'lodash.pick';
import { gmRequest } from './';

const validFilters = ['skip', 'take', 'tags'];

const buildQuery = (query) =>
  Object.entries(pick(query, validFilters))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export default {
  getArticle: (slug) => gmRequest(`/api/v1/articles/${slug}`),
  getArticles: (query) => gmRequest(`/api/cms/articles?${buildQuery(query)}`),
  getArticlePromotion: () => gmRequest('/api/cms/article-promotion'),
};
