import { createResponsiveStateReducer } from 'redux-responsive';

import { screenSizes } from 'styles/media';

/* treat >= 768 as desktop view, for instance: 768 is desktop, but 767 is mobile */
function offsetScreenSizes(breakpoints) {
  const offsetBreakpoints = breakpoints;
  offsetBreakpoints.md -= 1;
  return offsetBreakpoints;
}

export default createResponsiveStateReducer(offsetScreenSizes(screenSizes), {
  initialMediaType: 'xs',
});

export const selectIsMobile = state => !state.viewport.greaterThan.md;
export const selectBreakpointIsInfinity = state => state.viewport.greaterThan.xl;
export const selectBreakpoint = state => state.viewport.mediaType;
