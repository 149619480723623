export const FALLBACK_SEARCH_THUMBNAIL_SVG = '/images-next/search/vendor-fallback-thumbnail.svg';
export const FALLBACK_SEARCH_THUMBNAIL_URL =
  'https://media-api.xogrp.com/images/dfdb0a11-f937-4cd6-b928-4bfb40348599';
export const SEARCH_THUMBNAIL_SIZE = 250;

// Biggest thumbnail size on a mobile device
export const SEARCH_THUMBNAIL_SIZE_MOBILE = 348;

export const STANDARD_SEARCH = 'STANDARD_SEARCH';
export const VENUE_SEARCH = 'VENUE_SEARCH';
export const INVALID_VENUE_SEARCH = 'INVALID_VENUE_SEARCH';
export const OBSCURE_CATEGORY_SEARCH = 'OBSCURE_CATEGORY_SEARCH';
export const OBSCURE_NATIONAL_SEARCH = 'OBSCURE_NATIONAL_SEARCH';
export const INVALID_SEARCH = 'INVALID_SEARCH';
export const VENDOR_DIRECTORY = 'VENDOR_DIRECTORY';

export const searchDisplayNames = Object.freeze({
  [STANDARD_SEARCH]: 'Standard',
  [VENUE_SEARCH]: 'Standard',
  [INVALID_VENUE_SEARCH]: 'Standard',
  [OBSCURE_CATEGORY_SEARCH]: 'Obscure',
  [OBSCURE_NATIONAL_SEARCH]: 'Obscure',
});

export const NUM_VENDORS_PER_PAGE = 25;
export const NUM_VENDORS_TO_LOAD_MORE = 12;

export const DESCRIPTION_CHAR_LIMIT = 220;

export const SEARCH_SORT_TYPES = {
  RECOMMENDED: 1,
  MOST_REVIEWED: 2,
  MOST_BOOKED: 3,
};

export const OBSCURE_SEARCH_RESULT_SETS = Object.freeze({
  PRIMARY_RESULTS: 1,
  RELATED_VENDORS: 2,
});

export const SEARCH_TILE_ACTIONS = Object.freeze({
  CONTACT: 'Contact Profile Click',
  VIEW_PROFILE_BUTTON: 'View Profile Button Click',
  VIEW_PROFILE_TILE: 'Profile Tile Click',
});
