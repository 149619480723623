import api from 'api/events';
import isempty from 'lodash.isempty';
import { ERROR, LOADED, LOADING } from 'state/status';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import mergeState from 'state/utils/mergeState';

const initialState = {
  entities: {},
  errors: {},
  fetchStatus: {},
};

export const GET_EVENT_REQUEST = 'the-bash/events/GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'the-bash/events/GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'the-bash/events/GET_EVENT_FAILURE';
export const GET_EVENT_NOT_FOUND = 'the-bash/events/GET_EVENT_NOT_FOUND';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      const { events = initialState } = action.payload;
      return mergeState(initialState, state, events);
    case GET_EVENT_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.eventSlug]: LOADING,
        },
      };
    }
    case GET_EVENT_SUCCESS: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.eventSlug]: LOADED,
        },
        entities: {
          ...state.entities,
          [action.eventSlug]: action.event,
        },
      };
    }
    case GET_EVENT_FAILURE: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.eventSlug]: action.error,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [action.eventSlug]: ERROR,
        },
      };
    }
    case GET_EVENT_NOT_FOUND: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.eventSlug]: ERROR,
        },
        errors: {
          ...state.errors,
          [action.eventSlug]: {
            statusCode: 404,
          },
        },
      };
    }
    default:
      return state;
  }
}

// Selectors
export const selectEventError = (state, url) => state.events.errors[url];
export const selectEventFetchStatus = (state, url) => state.events.fetchStatus[url];
export const selectEventHasError = (state, url) => selectEventFetchStatus(state, url) === ERROR;
export const selectEvent = (state, url) => state.events.entities[url] || null;
export const selectEventIsLoaded = (state, url) => selectEventFetchStatus(state, url) === LOADED;

// Actions
export const getEvent = (eventSlug) => ({
  type: GET_EVENT_REQUEST,
  eventSlug,
});

export function* getEventAsync(action) {
  const { eventSlug } = action;

  try {
    const event = yield call([api, api.getEventContent], eventSlug);

    if (isempty(event)) {
      yield put({
        type: GET_EVENT_NOT_FOUND,
        eventSlug,
      });
    } else {
      yield put({
        type: GET_EVENT_SUCCESS,
        eventSlug,
        event,
      });
    }
  } catch (error) {
    yield put({
      type: GET_EVENT_FAILURE,
      error,
      eventSlug,
    });
  }
}

export function* eventsSaga() {
  yield all([takeEvery(GET_EVENT_REQUEST, getEventAsync)]);
}
