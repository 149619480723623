import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { ARTICLES_PER_PAGE } from 'constants/Articles';
import { articleSearchTerm } from 'state/modules/articles/helpers';
import mergeState from 'state/utils/mergeState';
import api from 'api/articles';
import { ERROR, LOADED, LOADING } from '../../status';
import { HYDRATE } from 'next-redux-wrapper';

export const ALL_ARTICLES = 'ALL_ARTICLES';
export const ARTICLE_PROMOTION = 'ARTICLE_PROMOTION';

export const GET_ARTICLE_REQUEST = 'the-bash/articles/GET_ARTICLE_REQUEST';
export const GET_ARTICLE_SUCCESS = 'the-bash/articles/GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAILURE = 'the-bash/articles/GET_ARTICLE_FAILURE';
export const GET_ALL_ARTICLES_REQUEST = 'the-bash/articles/GET_ALL_ARTICLES_REQUEST';
export const GET_ALL_ARTICLES_SUCCESS = 'the-bash/articles/GET_ALL_ARTICLES_SUCCESS';
export const GET_ALL_ARTICLES_FAILURE = 'the-bash/articles/GET_ALL_ARTICLES_FAILURE';
export const GET_ARTICLE_PROMOTION_REQUEST = 'the-bash/articles/GET_ARTICLE_PROMOTION_REQUEST';
export const GET_ARTICLE_PROMOTION_SUCCESS = 'the-bash/articles/GET_ARTICLE_PROMOTION_SUCCESS';
export const GET_ARTICLE_PROMOTION_FAILURE = 'the-bash/articles/GET_ARTICLE_PROMOTION_FAILURE';

const initialState = {
  entities: {},
  fetchStatus: {},
  errors: {},
  total: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      const { articles = initialState } = action.payload;
      return mergeState(initialState, state, articles);
    case GET_ARTICLE_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.slug]: LOADING,
        },
      };
    }
    case GET_ALL_ARTICLES_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [articleSearchTerm(action.query)]: LOADING,
        },
      };
    }
    case GET_ARTICLE_PROMOTION_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [ARTICLE_PROMOTION]: LOADING,
        },
      };
    }

    case GET_ARTICLE_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.article.slug]: action.article,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [action.article.slug]: LOADED,
        },
        errors: {
          [action.article.slug]: null,
        },
      };
    }
    case GET_ALL_ARTICLES_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [articleSearchTerm(action.query)]: action.articles,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [articleSearchTerm(action.query)]: LOADED,
        },
        errors: {
          [articleSearchTerm(action.query)]: null,
        },
        total: {
          ...state.total,
          [articleSearchTerm(action.query, { omit: ['page'] })]: action.total,
        },
      };
    }
    case GET_ARTICLE_PROMOTION_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [ARTICLE_PROMOTION]: action.articlePromotion,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [ARTICLE_PROMOTION]: LOADED,
        },
        errors: {
          [ARTICLE_PROMOTION]: null,
        },
      };
    }
    case GET_ARTICLE_FAILURE: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.slug]: ERROR,
        },
        errors: {
          [action.slug]: action.error,
        },
      };
    }
    case GET_ALL_ARTICLES_FAILURE: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [articleSearchTerm(action.query)]: ERROR,
        },
        errors: {
          [articleSearchTerm(action.query)]: action.error,
        },
      };
    }
    case GET_ARTICLE_PROMOTION_FAILURE: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [ARTICLE_PROMOTION]: ERROR,
        },
        errors: {
          [ARTICLE_PROMOTION]: action.error,
        },
      };
    }
    default:
      return state;
  }
}

// Selectors
export const selectAllArticlesLoaded = createSelector(
  (state) => state.articles.fetchStatus,
  (_, query) => query,
  (fetchStatus, query) => fetchStatus[articleSearchTerm(query)] === LOADED
);
export const selectAllArticlesHasError = createSelector(
  (state) => state.articles.fetchStatus,
  (_, query) => query,
  (fetchStatus, query) => fetchStatus[articleSearchTerm(query)] === ERROR
);
export const selectAllArticlesErrorContent = createSelector(
  (state) => state.articles.errors,
  (_, query) => query,
  (errors, query) => errors[articleSearchTerm(query)] || null
);
export const selectAllArticles = createSelector(
  (state) => state.articles.entities,
  (_, query) => query,
  (articles, query) => articles[articleSearchTerm(query)] || []
);
export const selectArticlesTotal = createSelector(
  (state) => state.articles.total,
  (_, query) => query,
  (articles, query) => articles[articleSearchTerm(query, { omit: ['page'] })] || 0
);

export const selectArticle = createSelector(
  (state) => state.articles.entities,
  (_, slug) => slug,
  (entities, slug) => entities[slug] || null
);

export const selectArticlePromotion = createSelector(
  (state) => state.articles.entities,
  (entities) => entities[ARTICLE_PROMOTION] || null
);

export const selectArticlePromotionLoaded = createSelector(
  (state) => state.articles.fetchStatus,
  (fetchStatus) => fetchStatus[ARTICLE_PROMOTION] === LOADED
);

// Actions
export const getArticle = (slug) => ({ type: GET_ARTICLE_REQUEST, slug });

export function* getArticleAsync(action) {
  try {
    const article = yield call([api, api.getArticle], action.slug);
    yield put({ type: GET_ARTICLE_SUCCESS, article });
  } catch (error) {
    yield put({ type: GET_ARTICLE_FAILURE, slug: action.slug, error });
  }
}

export const getAllArticles = (query) => ({ type: GET_ALL_ARTICLES_REQUEST, query });

export function* getAllArticlesAsync({ query }) {
  const { page } = query;
  try {
    const skip = (parseInt(page, 10) - 1) * ARTICLES_PER_PAGE;
    const apiQuery = { skip, take: ARTICLES_PER_PAGE, ...query };
    const { articles, total } = yield call([api, api.getArticles], apiQuery);
    yield put({ type: GET_ALL_ARTICLES_SUCCESS, query, articles, total });
  } catch (error) {
    yield put({ type: GET_ALL_ARTICLES_FAILURE, query, error });
  }
}

export const getArticlePromotion = () => ({ type: GET_ARTICLE_PROMOTION_REQUEST });

export function* getArticlePromotionAsync() {
  try {
    const articlePromotion = yield call([api, api.getArticlePromotion]);
    yield put({ type: GET_ARTICLE_PROMOTION_SUCCESS, articlePromotion });
  } catch (error) {
    yield put({ type: GET_ARTICLE_PROMOTION_FAILURE, error });
  }
}

export function* articlesSaga() {
  yield all([takeLatest(GET_ARTICLE_REQUEST, getArticleAsync)]);
  yield all([takeLatest(GET_ALL_ARTICLES_REQUEST, getAllArticlesAsync)]);
  yield all([takeLatest(GET_ARTICLE_PROMOTION_REQUEST, getArticlePromotionAsync)]);
}
