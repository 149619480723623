import { getIsSearchById } from 'helpers/search';
import { gmRequest } from './';

export default {
  getSearchCopy: (query) => {
    const { location } = query;
    let { slug } = query;

    if (location) {
      slug = `${slug}-${location}`;
    }

    return gmRequest(`/api/cms/search/${slug}`);
  },

  getSearchLookupForCityGroups: (searchString) =>
    gmRequest(`/api/v1/search-lookups/${searchString}/city-groups`),

  getVendors: (skip, take, query) => {
    const {
      slug,
      location,
      venueType,
      overrideSortSeed,
      excludeNonPaidVenues,
      primaryCategoryOnly,
      exactLocationOnly,
      distance,
      minNumberOfReviews,
      eventDate,
      acceptsOnlineDeposits,
      minRating,
      includeInVirtualEvents,
      obscureSearchResultSet,
      useDefaultSearchDistance,
      sort,
    } = query;

    const isSearchById = getIsSearchById(query);
    const searchEndpoint = isSearchById ? 'search-lookup-id' : 'url';
    let url = `/api/v1/search/${searchEndpoint}/${slug}?skip=${skip}&take=${take}`;

    if (location) url = `${url}&location=${location}`;
    if (venueType) url = `${url}&overrideCategory=${venueType}`;
    if (overrideSortSeed) url = `${url}&overrideSortSeed=${overrideSortSeed}`;
    if (excludeNonPaidVenues) url = `${url}&excludeNonPaidVenues=${excludeNonPaidVenues}`;
    if (primaryCategoryOnly) url = `${url}&primaryCategoryOnly=${primaryCategoryOnly}`;
    if (exactLocationOnly) url = `${url}&exactLocationOnly=${exactLocationOnly}`;
    if (obscureSearchResultSet) url = `${url}&obscureSearchResultSet=${obscureSearchResultSet}`;
    if (distance) url += `&distance=${distance}`;
    if (minNumberOfReviews) url += `&minNumberOfReviews=${minNumberOfReviews}`;
    if (eventDate) url += `&eventDate=${eventDate.toISOString().split('T')[0]}`;
    if (acceptsOnlineDeposits) url += `&acceptsOnlineDeposits=${acceptsOnlineDeposits}`;
    if (minRating) url += `&minRating=${minRating}`;
    if (includeInVirtualEvents) url += `&includeInVirtualEvents=${includeInVirtualEvents}`;
    if (useDefaultSearchDistance) url += `&useDefaultSearchDistance=true`;
    if (sort) url += `&sort=${sort}`;

    return gmRequest(url);
  },

  getVendorsByIpGeolocation: (serviceType, take, ipAddress = null) => {
    let url = `/api/v1/search/service-type/${serviceType}?take=${take}`;
    if (ipAddress) {
      url = `${url}&ip=${ipAddress}`;
    }
    return gmRequest(url);
  },

  trackImpressions: (data) =>
    gmRequest('/api/v1/members/tracksearchhit', { method: 'POST', body: JSON.stringify(data) }),

  membersAutocomplete: (startsWith, useTheDjRule = null) => {
    let url = `/api/v1/members/autocomplete?startsWith=${startsWith}`;
    if (useTheDjRule !== null) {
      url += `&useTheDjRule=${useTheDjRule}`;
    }

    return gmRequest(url);
  },
  logSearchTileInteraction: (data) =>
    gmRequest('/api/v1/search-tile-action', { method: 'POST', body: JSON.stringify(data) }),
};
