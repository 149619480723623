import React, { useEffect } from 'react';
import Router from 'next/router';
import {
  Nav as SharedHeader,
  clientsApiFactory,
  initializeFromLocalStorage,
  memberApiFactory,
  serviceTypesApiFactory,
  tokenApiFactory,
} from '@tkww/the-bash-frontend';
import { useDispatch, useSelector } from 'react-redux';

import cookieHelper from 'helpers/cookieHelper';
import { gmConfig } from 'helpers/config';
import localStorageHelper from 'helpers/localStorageHelper';
import { selectIsMobile } from 'state/modules/viewport';

export const Header = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectIsMobile);
  useEffect(() => {
    dispatch(initializeFromLocalStorage(gmConfig.cookie.prefix));
  }, [dispatch]);

  return (
    <SharedHeader
      clientsApiClient={clientsApiFactory(`https://${gmConfig.domains.www}`)}
      configDomains={gmConfig.domains}
      cookieHelper={cookieHelper}
      localStorageHelper={localStorageHelper}
      membersApiClient={memberApiFactory(`https://${gmConfig.domains.www}`)}
      routeChangeListener={(onChange) => {
        Router.events.on('routeChangeComplete', onChange);
      }}
      serviceTypesApiClient={serviceTypesApiFactory(`https://${gmConfig.domains.www}`)}
      track={() => {}}
      isMobile={isMobile}
      membershipSelectionUrl={gmConfig.mcpMembershipSelectionUrl}
      tokenApiClient={tokenApiFactory(`https://${gmConfig.domains.www}`, cookieHelper)}
    />
  );
};

export default Header;
