import { gmRequest } from './';

// eslint-disable-next-line no-underscore-dangle
const _getCalendarEvents = (memberId, skip, take, isPast) =>
  gmRequest(
    `/api/v1/profile/calendar-events?memberId=${memberId}&isPast=${isPast}&skip=${skip}&take=${take}`
  );

export default {
  getProfile: (directory) => gmRequest(`/api/v1/profile${directory}`),
  getServiceAttributes: () => gmRequest('/api/v1/serviceattributes'),
  getReviews: (directory, skip = 0, take, sortType) => {
    let queryString = `?skip=${skip}&take=${take}`;
    if (sortType) {
      queryString += `&sortType=${sortType}`;
    }

    return gmRequest(`/api/v1/profiles${directory}/feedback${queryString}`);
  },
  getPastEvents: (memberId, skip, take) => _getCalendarEvents(memberId, skip, take, true),
  getUpcomingEvents: (memberId, skip, take) => _getCalendarEvents(memberId, skip, take, false),
  getBlackoutDates: (memberId, month, year) =>
    gmRequest(`/api/v1/profile/blackout-dates?memberId=${memberId}&year=${year}&month=${month}`),
  getCalendarEntries: (memberId, month, year) =>
    gmRequest(`/api/v1/profile/calendar-entries?memberId=${memberId}&year=${year}&month=${month}`),
  getVenueUrls: (memberId, month, year) => gmRequest('/api/v1/members/profile/venue-urls'),
  trackImpressions: (memberID) =>
    gmRequest(`/api/v1/members/${memberID}/tracksearchhit`, {
      method: 'POST',
      body: JSON.stringify({ type: 'Profile' }),
    }),
  getProfilePhotos: (id, skip = 0, take = null) => {
    let path = `/api/v1/profiles/${id}/photos?skip=${skip}`;
    if (take !== null) {
      path += `&take=${take}`;
    }
    return gmRequest(path);
  },
};
