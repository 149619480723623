import isEmpty from 'lodash.isempty';
import { gmRequest } from '.';

const getQueryString = (contentType, stateAbbreviation, letterGrouping) => {
  let queryString = `servicePageType=${contentType}`;
  if (!isEmpty(stateAbbreviation)) {
    queryString = `${queryString}&stateAbbreviation=${stateAbbreviation}`;
  }
  if (!isEmpty(letterGrouping)) {
    queryString = `${queryString}&cityFirstLetter=${letterGrouping}`;
  }
  return queryString;
};

export default {
  getServiceContent: (url, contentType, stateAbbreviation, letterGrouping) =>
    gmRequest(
      `/api/cms/service/${url}?${getQueryString(contentType, stateAbbreviation, letterGrouping)}`
    ),
};
