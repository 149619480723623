import { all, call } from 'redux-saga/effects';

import {
  analyticsSaga,
  localStorageSagas,
  locationsSagas,
  searchLookupsSagas,
} from '@tkww/the-bash-frontend';

import { articlesSaga } from '../modules/articles';
import { eventsSaga } from '../modules/events';
import { profilesSaga } from '../modules/profiles';
import { searchSaga } from '../modules/search';
import { serviceAttributesSaga } from '../modules/serviceAttributes';
import { servicesSaga } from '../modules/services';
import { themesSaga } from '../modules/themes';
import { venuesSaga } from '../modules/venueMarkets';

export default function* rootSaga() {
  yield all([
    call(analyticsSaga),
    call(articlesSaga),
    call(eventsSaga),
    call(localStorageSagas),
    call(locationsSagas),
    call(profilesSaga),
    call(searchSaga),
    call(searchLookupsSagas),
    call(serviceAttributesSaga),
    call(servicesSaga),
    call(themesSaga),
    call(venuesSaga),
  ]);
}
