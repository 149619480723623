export const SERVICES = 'Services';
export const TOP_LEVEL_SERVICE_PAGE = 'Top-level';
export const MID_LEVEL_SERVICE_PAGE = 'Mid-level';
export const INDIVIDUAL_SERVICE_PAGE = 'Individual';
export const STATE_SERVICE_PAGE = 'Individual, State Locations';
export const CITY_BY_LETTER_SERVICE_PAGE = 'Individual, City by Letter';
export const BG_IMAGE_SEARCH_SERVICE_PAGE =
  'https://media-api.xogrp.com/images/images/040ac7a4-bdca-4eda-8111-fd3a8a026dd0';
export const POPULAR_CITIES_TITLE = 'Popular Cities in';
export const POPULAR_COUNTIES_TITLE = 'Popular Counties in';
export const BY_LETTER_LOCATION_TYPE_CITY = 'City';
export const AZ_LIST_TITLE = 'Browse More Cities';

export const DEFAULT_CITY_BY_LETTER_GROUPINGS = ['A - J', 'K - Q', 'R - Z'];

export const serviceCMSTypes = Object.freeze({
  [TOP_LEVEL_SERVICE_PAGE]: 'Toplevel',
  [MID_LEVEL_SERVICE_PAGE]: 'Midlevel',
  [INDIVIDUAL_SERVICE_PAGE]: 'Individual',
  [STATE_SERVICE_PAGE]: 'Individual',
  [CITY_BY_LETTER_SERVICE_PAGE]: 'Individual',
});

export const serviceCategories = {
  [TOP_LEVEL_SERVICE_PAGE]: 'topLevelCategory',
  [MID_LEVEL_SERVICE_PAGE]: 'midLevelCategory',
  [INDIVIDUAL_SERVICE_PAGE]: 'serviceCategory',
  [STATE_SERVICE_PAGE]: 'serviceCategory',
  [CITY_BY_LETTER_SERVICE_PAGE]: 'serviceCategory',
};

export const MID_LEVEL_LIVEBAND = 'liveband';
export const MID_LEVEL_IMPERSONATORS = 'impersonator';
export const MID_LEVEL_DJ = 'dj';

export const servicePageSearchSlugs = Object.freeze({
  [MID_LEVEL_DJ]: 'dj',
  [MID_LEVEL_IMPERSONATORS]: 'impersonator',
  [MID_LEVEL_LIVEBAND]: 'live-band',
});

export const maxTopVendorsForServicePageType = Object.freeze({
  [MID_LEVEL_SERVICE_PAGE]: 8,
  [INDIVIDUAL_SERVICE_PAGE]: 4,
  [STATE_SERVICE_PAGE]: 4,
  [CITY_BY_LETTER_SERVICE_PAGE]: 4,
});
