import { request } from './';

export default {
  getTheme: (slug) => request(`/themes/${slug}`),
  getAllThemes: () => request('/themes'),
  getThemeCount: () => request('/themes?load=countOnly'),
  getThemesForIngredient: (ingredient) => request(`/themes?ingredient=${ingredient}`),
  getThemesForConcept: (concept) => request(`/themes?concept=${concept}`),
  getThemesFilteredBySlugs: (filterBySlugs) =>
    request(`/themes?filterBySlugs=${filterBySlugs.join()}`),
};
