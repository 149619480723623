import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import api from 'api/themes';
import { ERROR, LOADED, LOADING } from '../../status';
import mergeState from 'state/utils/mergeState';
import { HYDRATE } from 'next-redux-wrapper';

export const GET_THEME_REQUEST = 'the-bash/themes/GET_THEME_REQUEST';
export const GET_THEME_SUCCESS = 'the-bash/themes/GET_THEME_SUCCESS';
export const GET_THEME_FAILURE = 'the-bash/themes/GET_THEME_FAILURE';

export const GET_ALL_THEMES_REQUEST = 'the-bash/themes/GET_ALL_THEMES_REQUEST';
export const GET_ALL_THEMES_SUCCESS = 'the-bash/themes/GET_ALL_THEMES_SUCCESS';
export const GET_ALL_THEMES_FAILURE = 'the-bash/themes/GET_ALL_THEMES_FAILURE';

export const GET_THEMES_FOR_INGREDIENT_REQUEST =
  'the-bash/themes/GET_THEMES_FOR_INGREDIENT_REQUEST';
export const GET_THEMES_FOR_INGREDIENT_SUCCESS =
  'the-bash/themes/GET_THEMES_FOR_INGREDIENT_SUCCESS';
export const GET_THEMES_FOR_INGREDIENT_FAILURE =
  'the-bash/themes/GET_THEMES_FOR_INGREDIENT_FAILURE';

export const GET_THEMES_FOR_CONCEPT_REQUEST = 'the-bash/themes/GET_THEMES_FOR_CONCEPT_REQUEST';
export const GET_THEMES_FOR_CONCEPT_SUCCESS = 'the-bash/themes/GET_THEMES_FOR_CONCEPT_SUCCESS';
export const GET_THEMES_FOR_CONCEPT_FAILURE = 'the-bash/themes/GET_THEMES_FOR_CONCEPT_FAILURE';

export const GET_FILTERED_THEMES_REQUEST = 'the-bash/themes/GET_FILTERED_THEMES_REQUEST';
export const GET_FILTERED_THEMES_SUCCESS = 'the-bash/themes/GET_FILTERED_THEMES_SUCCESS';
export const GET_FILTERED_THEMES_FAILURE = 'the-bash/themes/GET_FILTERED_THEMES_FAILURE';

export const GET_THEME_COUNT_REQUEST = 'the-bash/themes/GET_THEME_COUNT_REQUEST';
export const GET_THEME_COUNT_SUCCESS = 'the-bash/themes/GET_THEME_COUNT_SUCCESS';
export const GET_THEME_COUNT_FAILURE = 'the-bash/themes/GET_THEME_COUNT_FAILURE';

const initialState = {
  entities: {},
  fetchStatus: {},
  errors: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      const { themes = initialState } = action.payload;
      return mergeState(initialState, state, themes);
    case GET_THEME_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.slug]: LOADING,
        },
      };
    case GET_ALL_THEMES_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          allThemes: LOADING,
        },
      };
    case GET_THEME_COUNT_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          themeCount: LOADING,
        },
      };
    case GET_FILTERED_THEMES_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.key]: LOADING,
        },
      };
    case GET_THEMES_FOR_INGREDIENT_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [`ingredient/${action.slug}`]: LOADING,
        },
      };
    case GET_THEMES_FOR_CONCEPT_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [`concept/${action.slug}`]: LOADING,
        },
      };
    case GET_THEME_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.theme.slug]: action.theme,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [action.theme.slug]: LOADED,
        },
        errors: {
          [action.theme.slug]: null,
        },
      };
    case GET_ALL_THEMES_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          allThemes: action.themes.sort((a, b) => a.title.localeCompare(b.title)),
        },
        fetchStatus: {
          ...state.fetchStatus,
          allThemes: LOADED,
        },
        errors: {
          allThemes: null,
        },
      };
    }
    case GET_THEME_COUNT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          themeCount: action.count,
        },
        fetchStatus: {
          ...state.fetchStatus,
          themeCount: LOADED,
        },
        errors: {
          ...state.errors,
          themeCount: null,
        },
      };
    case GET_FILTERED_THEMES_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.key]: action.themes,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [action.key]: LOADED,
        },
        errors: {
          [action.key]: null,
        },
      };
    case GET_THEMES_FOR_INGREDIENT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [`ingredient/${action.slug}`]: action.themes,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [`ingredient/${action.slug}`]: LOADED,
        },
        errors: {
          [`ingredient/${action.slug}`]: null,
        },
      };
    case GET_THEMES_FOR_CONCEPT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [`concept/${action.slug}`]: action.themes,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [`concept/${action.slug}`]: LOADED,
        },
        errors: {
          [`concept/${action.slug}`]: null,
        },
      };
    case GET_THEME_FAILURE:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.slug]: ERROR,
        },
        errors: {
          [action.slug]: action.error,
        },
      };
    case GET_ALL_THEMES_FAILURE:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          allThemes: ERROR,
        },
        errors: {
          ...state.errors,
          allThemes: action.error,
        },
      };
    case GET_THEME_COUNT_FAILURE:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          themeCount: ERROR,
        },
        errors: {
          ...state.errors,
          themeCount: action.error,
        },
      };
    case GET_FILTERED_THEMES_FAILURE:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.key]: ERROR,
        },
        errors: {
          ...state.errors,
          [action.key]: action.error,
        },
      };
    case GET_THEMES_FOR_INGREDIENT_FAILURE:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [`ingredient/${action.slug}`]: ERROR,
        },
        errors: {
          ...state.errors,
          [`ingredient/${action.slug}`]: action.error,
        },
      };
    case GET_THEMES_FOR_CONCEPT_FAILURE:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [`concept/${action.slug}`]: ERROR,
        },
        errors: {
          ...state.errors,
          [`concept/${action.slug}`]: action.error,
        },
      };
    default:
      return state;
  }
}

export const selectThemes = (key) => (state) => state.themes.entities[key] || null;
export const selectThemesLoaded = (key) => (state) => state.themes.fetchStatus[key] === LOADED;
export const selectThemesHasError = (key) => (state) => state.themes.fetchStatus[key] === ERROR;
export const selectThemesErrorContent = (key) => (state) => state.themes.errors[key];
export const selectRelatedThemes = (key) => (state) => state.themes.entities[key] || [];

const selectThemeCountStatus = (state) => state.themes.fetchStatus.themeCount;
export const selectThemeCountLoaded = (state) => selectThemeCountStatus(state) === LOADED;
export const selectThemeCount = (state) => state.themes.entities.themeCount || null;
export const selectThemeCountHasError = (state) => selectThemeCountStatus(state) === ERROR;
export const selectThemeCountErrorContent = (state) => state.themes.errors.themeCount;

export const getTheme = (slug) => ({ type: GET_THEME_REQUEST, slug });
export const getThemesForIngredient = (slug) => ({ type: GET_THEMES_FOR_INGREDIENT_REQUEST, slug });
export const getThemesForConcept = (slug) => ({ type: GET_THEMES_FOR_CONCEPT_REQUEST, slug });
export const getThemesFilteredBySlugs = (key, slugs) => ({
  type: GET_FILTERED_THEMES_REQUEST,
  key,
  slugs,
});

export const getAllThemes = () => ({ type: GET_ALL_THEMES_REQUEST });
export const getThemeCount = () => ({ type: GET_THEME_COUNT_REQUEST });

export function* getAllThemesAsync() {
  try {
    const { themes } = yield call([api, api.getAllThemes]);
    yield put({ type: GET_ALL_THEMES_SUCCESS, themes });
  } catch (error) {
    yield put({ type: GET_ALL_THEMES_FAILURE, error });
  }
}

export function* getThemeCountAsync() {
  try {
    const themeCountLoaded = yield select(selectThemeCountLoaded);
    if (!themeCountLoaded) {
      const { count } = yield call([api, api.getThemeCount]);
      yield put({ type: GET_THEME_COUNT_SUCCESS, count });
    }
  } catch (error) {
    yield put({ type: GET_THEME_COUNT_FAILURE, error });
  }
}

export function* getThemesFilteredBySlugsAsync(action) {
  try {
    const { themes } = yield call([api, api.getThemesFilteredBySlugs], action.slugs);
    yield put({ type: GET_FILTERED_THEMES_SUCCESS, themes, key: action.key });
  } catch (error) {
    yield put({ type: GET_FILTERED_THEMES_FAILURE, error, key: action.key });
  }
}

export function* getThemesForIngredientAsync(action) {
  try {
    const { themes } = yield call([api, api.getThemesForIngredient], action.slug);
    yield put({ type: GET_THEMES_FOR_INGREDIENT_SUCCESS, themes, slug: action.slug });
  } catch (error) {
    yield put({ type: GET_THEMES_FOR_INGREDIENT_FAILURE, error, slug: action.slug });
  }
}

export function* getThemesForConceptAsync(action) {
  try {
    const { themes } = yield call([api, api.getThemesForConcept], action.slug);
    yield put({ type: GET_THEMES_FOR_CONCEPT_SUCCESS, themes, slug: action.slug });
  } catch (error) {
    yield put({ type: GET_THEMES_FOR_CONCEPT_FAILURE, error, slug: action.slug });
  }
}

const normalizeRelatedThemes = (relatedThemes) => {
  const normalizedThemes = [];

  if (!relatedThemes || !Array.isArray(relatedThemes)) {
    return normalizedThemes;
  }

  relatedThemes.forEach((rt) => {
    const ingredients = [];
    rt.ingredients.forEach((i) => {
      ingredients.push({
        id: i.id,
        title: i.title,
        type: i.type,
      });
    });
    normalizedThemes.push({
      ...rt,
      ingredients,
    });
  });

  return { relatedThemes: normalizedThemes };
};

const normalizeTheme = (theme) => {
  const {
    id,
    title,
    alternateTitle,
    slug,
    shortDescription,
    description,
    metaTitle,
    metaDescription,
    themeType,
    themeTypeDescription,
    contentFooter,
    video,
    image,
    relatedThemes,
    ingredients,
    timestamp,
  } = theme;

  return {
    id,
    title,
    alternateTitle,
    slug,
    shortDescription,
    description,
    metaTitle,
    metaDescription,
    themeType,
    themeTypeDescription,
    contentFooter,
    video,
    image,
    ...normalizeRelatedThemes(relatedThemes),
    ingredients,
    timestamp,
  };
};

export function* getThemeAsync(action) {
  const { slug } = action;
  try {
    const themeSelector = yield call(selectThemesLoaded, slug);
    const themeLoaded = yield select(themeSelector);
    if (!themeLoaded) {
      const theme = yield call([api, api.getTheme], action.slug);

      yield put({ type: GET_THEME_SUCCESS, theme: normalizeTheme(theme) });
    }
  } catch (error) {
    yield put({ type: GET_THEME_FAILURE, slug: action.slug, error });
  }
}

export function* themesSaga() {
  yield all([takeLatest(GET_THEME_REQUEST, getThemeAsync)]);
  yield all([takeLatest(GET_ALL_THEMES_REQUEST, getAllThemesAsync)]);
  yield all([takeLatest(GET_THEMES_FOR_INGREDIENT_REQUEST, getThemesForIngredientAsync)]);
  yield all([takeLatest(GET_THEMES_FOR_CONCEPT_REQUEST, getThemesForConceptAsync)]);
  yield all([takeLatest(GET_FILTERED_THEMES_REQUEST, getThemesFilteredBySlugsAsync)]);
  yield all([takeLatest(GET_THEME_COUNT_REQUEST, getThemeCountAsync)]);
}
