import { ALL_ARTICLES } from 'state/modules/articles/index';

export const articleSearchTerm = (query, options = {}) => {
  const keysToOmit = options.omit || [];
  const queryString = Object.entries(query)
    .filter(([key]) => !keysToOmit.includes(key))
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([key, value]) => encodeURI(`${key}=${value}`))
    .join('&');
  return `${ALL_ARTICLES}${queryString && `?${queryString}`}`;
};
