/* eslint-disable import/prefer-default-export */
import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
export const gmApiUrl = serverRuntimeConfig.gmApiUrl || publicRuntimeConfig.gmApiUrl;
export const { hostname } = serverRuntimeConfig;
export const { environment, gmConfig, segmentKey } = publicRuntimeConfig;

export default { apiUrl, environment, gmApiUrl, gmConfig, segmentKey };
