import api from 'api/profiles';
import { createSelector } from 'reselect';
import { ERROR, LOADED, LOADING } from 'state/status';
import mergeState from 'state/utils/mergeState';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  entities: [],
  fetchStatus: {},
  errors: {},
};

export const GET_SERVICE_ATTRIBUTES_REQUEST =
  'the-bash/serviceAttributes/GET_SERVICE_ATTRIBUTES_REQUEST';
export const GET_SERVICE_ATTRIBUTES_SUCCESS =
  'the-bash/serviceAttributes/GET_SERVICE_ATTRIBUTES_SUCCESS';
export const GET_SERVICE_ATTRIBUTES_FAILURE =
  'the-bash/serviceAttributes/GET_SERVICE_ATTRIBUTES_FAILURE';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      const { serviceAttributes = initialState } = action.payload;
      return mergeState(initialState, state, serviceAttributes);
    case GET_SERVICE_ATTRIBUTES_REQUEST:
      return {
        ...state,
        fetchStatus: LOADING,
      };
    case GET_SERVICE_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        entities: action.serviceAttributes,
        fetchStatus: LOADED,
        errors: {},
      };
    case GET_SERVICE_ATTRIBUTES_FAILURE:
      return {
        ...state,
        fetchStatus: ERROR,
        errors: action.error,
      };
    default:
      return state;
  }
}

export const getServiceAttributes = () => ({ type: GET_SERVICE_ATTRIBUTES_REQUEST });

export const selectServiceAttributesLoaded = (state) =>
  state.serviceAttributes.fetchStatus === LOADED;
export const selectServiceAttributes = (state) => state.serviceAttributes.entities || [];
export const selectServiceAttributesBySlugs = createSelector(
  selectServiceAttributes,
  (_, slugs) => slugs,
  (serviceAttributes, slugs) => serviceAttributes.filter((sa) => slugs.includes(sa.slug))
);

export function* getServiceAttributesAsync() {
  try {
    const serviceAttributesLoaded = yield select(selectServiceAttributesLoaded);
    if (!serviceAttributesLoaded) {
      const serviceAttributes = yield call([api, api.getServiceAttributes]);
      yield put({
        type: GET_SERVICE_ATTRIBUTES_SUCCESS,
        serviceAttributes,
      });
    }
  } catch (error) {
    yield put({
      type: GET_SERVICE_ATTRIBUTES_FAILURE,
      error,
    });
  }
}

export function* serviceAttributesSaga() {
  yield all([takeLatest(GET_SERVICE_ATTRIBUTES_REQUEST, getServiceAttributesAsync)]);
}
