export const sendToGTM = (id, name, category, value) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: 'web-vitals',
    eventCategory: category,
    eventAction: name,
    eventValue: value,
    eventLabel: id,
    non_interaction: true,
  });
};
