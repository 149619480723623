import MUIGlobalStyles from '@mui/material/GlobalStyles';

const GlobalStyles = () => (
  <MUIGlobalStyles
    styles={(theme) => ({
      body: {
        WebkitFontSmoothing: 'antialiased',
        margin: 0,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
      },
      '#nprogress': {
        pointerEvents: 'none',
        position: 'relative',
        zIndex: 9999999,
      },
      '#nprogress .bar': {
        background: theme.palette.primary.main,
        height: '2px',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1031,
      },
    })}
  />
);

export default GlobalStyles;
