const getResizeImageUrl = (photoUrl, width, height, fitImageToBox = false, quality = null) => {
  if (!photoUrl) {
    return '';
  }
  if (photoUrl.indexOf('media-api.xogrp.com') === -1) {
    return photoUrl;
  }
  const transformationPrefix = photoUrl.indexOf('~') === -1 ? '~' : '-';
  let formattedUrl = `${photoUrl}${transformationPrefix}rs_${width}.${height}${
    fitImageToBox ? '.fit' : ''
  }`;

  if (quality !== null) {
    formattedUrl += `?quality=${quality}`;
  }

  return formattedUrl;
};

export default getResizeImageUrl;
