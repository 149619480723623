import {
  FALLBACK_SEARCH_THUMBNAIL_SVG,
  SEARCH_THUMBNAIL_SIZE,
  NUM_VENDORS_PER_PAGE,
  NUM_VENDORS_TO_LOAD_MORE,
} from 'constants/Search';
import { gmApiUrl } from 'helpers/config';

import getResizeImageUrl from 'helpers/media/getResizeImageUrl';
import { buildGmServiceTypeSearchLink, buildGmStateSearchLink } from 'helpers/buildGmSearch';
import { CONTROL_POOL } from 'constants/TestPools';

export const getBreadcrumbItems = ({ searchLocation, serviceType }) => {
  const serviceTypeLink = buildGmServiceTypeSearchLink({
    serviceType,
    searchCategoryId: serviceType.id,
  });

  const locationLink = buildGmStateSearchLink({
    serviceType,
    searchLocation,
  });

  return [
    {
      path: serviceTypeLink,
      nextPath: serviceTypeLink,
      label: serviceType.pluralForm,
    },
    {
      path: locationLink,
      nextPath: '/search/[slug]',
      label: searchLocation.stateOrProvince.displayName,
    },
    {
      label: `${searchLocation.displayName} ${serviceType.pluralForm}`,
    },
  ];
};

export const getSearchThumbnailUrl = (thumbnailPhotoUrl, resizeInstructions = {}) => {
  const {
    width = SEARCH_THUMBNAIL_SIZE,
    height = SEARCH_THUMBNAIL_SIZE,
    fitImageToBox = false,
  } = resizeInstructions;
  if (thumbnailPhotoUrl) {
    return getResizeImageUrl(thumbnailPhotoUrl, width, height, fitImageToBox);
  }
  return FALLBACK_SEARCH_THUMBNAIL_SVG;
};

export const getIsSearchById = (searchParams = {}) => {
  const { slug } = searchParams;
  if (!slug) throw new Error('getIsSearchById requires slug');
  return /^\d+$/.test(slug);
};

export const getLoadMoreResultsOffset = (numOfResults) => {
  if (numOfResults <= NUM_VENDORS_PER_PAGE) return 0;
  const additionalResults = numOfResults - NUM_VENDORS_PER_PAGE;
  return (
    (Math.ceil(additionalResults / NUM_VENDORS_TO_LOAD_MORE) - 1) * NUM_VENDORS_TO_LOAD_MORE +
    NUM_VENDORS_PER_PAGE
  );
};

export const searchUrl = (searchString) => `${gmApiUrl}/search/${searchString}`;

export const getNumVendorsWithVideoButton = (vendors = []) => {
  const vendorsWithVideoButton = vendors.filter((vendor) => vendor.firstVideo !== null);
  return vendorsWithVideoButton.length;
};

export const getNumFirstPageVendors = (vendors = []) => {
  return vendors.length;
};

// Test Pool Helpers
export const defaultActivePools = [];

export const getActivePool = (splitNumber, activePools = defaultActivePools) => {
  const pools = [CONTROL_POOL, ...activePools];
  const poolIndex = splitNumber % pools.length;

  return pools[poolIndex];
};

export const getPoolDistribution = (searchInfo) => {
  const { searchString } = searchInfo;
  let hash = 5381;

  for (let i = 0; i < searchString.length; i++) {
    hash = (hash << 5) + hash + searchString.charCodeAt(i);
  }

  return Math.abs(hash);
};

export const getDistanceFilterTestPool = (searchInfo) => {
  const { distanceFilterTestGroup } = searchInfo;

  if (distanceFilterTestGroup === 0) return 'control';
  if (distanceFilterTestGroup === 1) return 'min 5 vendors';
  if (distanceFilterTestGroup === 2) return 'min 12 vendors';

  return '';
};

export const getActiveSearchPageTestPool = (searchInfo) => {
  return getDistanceFilterTestPool(searchInfo);
};
