const mergeState = (stateDefinition, clientState, serverState) => {
  let mergedState = {};
  for (const [key] of Object.entries(stateDefinition)) {
    // Service attributes is the only place using an array vs our standard of objects
    // Can safely assume that the server has the latest data to use.
    if (Array.isArray(stateDefinition[key])) {
      mergedState[key] = serverState[key].length > 0 ? serverState[key] : clientState[key];
    } else {
      mergedState[key] = { ...clientState[key], ...serverState[key] };
    }
  }
  return mergedState;
};

export default mergeState;
