import createSagaMiddleWare from 'redux-saga';
import { gmConfig } from 'helpers/config';
import { compose, applyMiddleware, createStore } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import { createTracker } from 'redux-segment';
import { locationsApiFactory, searchLookupsApiFactory } from '@tkww/the-bash-frontend';

import isBot from 'helpers/isBot';

import rootReducer from '../rootReducer';
import rootSaga from '../rootSaga';

const composeEnhancers =
  // eslint-disable-next-line
  typeof window !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

function makeStore(context) {
  const sagaMiddleware = createSagaMiddleWare({
    context: {
      applicationName: 'node',
      cookiePrefix: gmConfig.cookie.prefix,
      cookieDomain: gmConfig.cookie.domain,
      locationsApi: locationsApiFactory(`https://${gmConfig.domains.www}`),
      searchLookupsApi: searchLookupsApiFactory(`https://${gmConfig.domains.www}`),
    },
  });
  let middleware = [sagaMiddleware];

  if (typeof window !== 'undefined' && !isBot(navigator.userAgent)) {
    const trackerMiddleware = createTracker();
    middleware = [...middleware, trackerMiddleware];
  }

  const store = createStore(
    rootReducer,
    composeEnhancers(
      createResponsiveStoreEnhancer({ calculateInitialState: false }),
      applyMiddleware(...middleware)
    )
  );
  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };
  store.runSagaTask();

  return store;
}

export default createWrapper(makeStore, { debug: false });
