export const LITE = 'Lite';
export const BASIC = 'Basic';
export const PROFESSIONAL = 'Professional';
export const GOLD = 'Gold';

const membershipLevels = {
  LITE,
  BASIC,
  PROFESSIONAL,
  GOLD,
};

export default membershipLevels;
