import api from 'api/services';
import { searchLookupsApiFactory, locationsApiFactory } from '@tkww/the-bash-frontend';
import isempty from 'lodash.isempty';
import { ERROR, LOADED, LOADING } from 'state/status';
import mergeState from 'state/utils/mergeState';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { gmApiUrl } from 'helpers/config';
import {
  INDIVIDUAL_SERVICE_PAGE,
  STATE_SERVICE_PAGE,
  CITY_BY_LETTER_SERVICE_PAGE,
  BY_LETTER_LOCATION_TYPE_CITY,
  serviceCMSTypes,
} from 'constants/Services';
import { transformToStatesAndCitiesList } from 'helpers/transformSearchLookups';
import { HYDRATE } from 'next-redux-wrapper';

export const getServiceKey = (serviceSlug, servicePageType, stateAbbreviation, letterGrouping) => {
  let key = `${serviceSlug}-${serviceCMSTypes[servicePageType]}`;

  if (!isempty(stateAbbreviation)) {
    key += `-${stateAbbreviation}`;
  }
  if (!isempty(letterGrouping)) {
    key += `-${letterGrouping}`;
  }
  return key;
};

const initialState = {
  errors: {},
  fetchStatus: {},
  cmsData: {},
  statesAndCities: {},
  redirect: {},
};

export const GET_SERVICE_REQUEST = 'the-bash/services/GET_SERVICE_REQUEST';
export const GET_SERVICE_SUCCESS = 'the-bash/services/GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAILURE = 'the-bash/services/GET_SERVICE_FAILURE';
export const GET_SERVICE_NOT_FOUND = 'the-bash/services/GET_SERVICE_NOT_FOUND';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      const { services = initialState } = action.payload;
      return mergeState(initialState, state, services);
    case GET_SERVICE_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.query.serviceSlug]: LOADING,
        },
      };
    }
    case GET_SERVICE_SUCCESS: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.servicePageKey]: LOADED,
        },
        cmsData: {
          ...state.cmsData,
          [action.servicePageKey]: action.serviceCMS,
        },
        statesAndCities: {
          ...state.statesAndCities,
          [action.servicePageKey]: action.statesAndCities,
        },
        redirect: {
          ...state.redirect,
          [action.servicePageKey]: action.redirect,
        },
      };
    }
    case GET_SERVICE_FAILURE: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.servicePageKey]: action.error,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [action.servicePageKey]: ERROR,
        },
      };
    }
    case GET_SERVICE_NOT_FOUND: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.servicePageKey]: ERROR,
        },
        errors: {
          ...state.errors,
          [action.servicePageKey]: {
            statusCode: 404,
          },
        },
      };
    }
    default:
      return state;
  }
}

// Selectors

export const selectServiceError = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  state.services.errors[
    getServiceKey(serviceSlug, servicePageType, stateAbbreviation, letterGrouping)
  ];

export const selectServiceFetchStatus = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  state.services.fetchStatus[
    getServiceKey(serviceSlug, servicePageType, stateAbbreviation, letterGrouping)
  ];

export const selectServiceHasError = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  selectServiceFetchStatus(
    state,
    serviceSlug,
    servicePageType,
    stateAbbreviation,
    letterGrouping
  ) === ERROR;

export const selectService = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  state.services.entities[
    getServiceKey(serviceSlug, servicePageType, stateAbbreviation, letterGrouping)
  ] || null;

export const selectServiceIsLoaded = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  selectServiceFetchStatus(
    state,
    serviceSlug,
    servicePageType,
    stateAbbreviation,
    letterGrouping
  ) === LOADED;

export const selectServiceCMSData = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  state.services.cmsData[
    getServiceKey(serviceSlug, servicePageType, stateAbbreviation, letterGrouping)
  ] || null;

export const selectServicePageRedirect = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  state.services.redirect[
    getServiceKey(serviceSlug, servicePageType, stateAbbreviation, letterGrouping)
  ] || null;

export const selectStatesAndCities = (
  state,
  serviceSlug,
  servicePageType,
  stateAbbreviation,
  letterGrouping
) =>
  state.services.statesAndCities[
    getServiceKey(serviceSlug, servicePageType, stateAbbreviation, letterGrouping)
  ];

// Actions

export const getService = (query, contentType, servicePageType) => ({
  type: GET_SERVICE_REQUEST,
  query,
  contentType,
  servicePageType,
});

export function* getServiceAsync(action) {
  const { query, contentType, servicePageType } = action;
  const { serviceSlug, stateAbbreviation, letterGrouping } = query;

  const servicePageKey = getServiceKey(
    serviceSlug,
    servicePageType,
    stateAbbreviation,
    letterGrouping
  );

  const searchLookupsApiClient = searchLookupsApiFactory(gmApiUrl);
  const locationsApiClient = locationsApiFactory(gmApiUrl);

  try {
    const serviceCMS = yield call(
      [api, api.getServiceContent],
      serviceSlug,
      contentType,
      stateAbbreviation,
      letterGrouping
    );
    if (isempty(serviceCMS)) {
      yield put({
        type: GET_SERVICE_NOT_FOUND,
        servicePageKey,
      });
      return;
    }
    const { redirectUrl } = serviceCMS;
    if (redirectUrl) {
      yield put({
        type: GET_SERVICE_SUCCESS,
        servicePageKey,
        serviceCMS,
        redirect: redirectUrl,
      });
      return;
    }

    let statesAndCities = null;
    let redirect = null;

    const statesAndCitiesQueryOptions = {
      serviceTypeId: serviceCMS?.serviceId,
      locationType: BY_LETTER_LOCATION_TYPE_CITY,
      onlyStateServicePageCities: true,
    };

    const kidsPartyCitiesQueryOptions = {
      onlyStateServicePageCities: true,
    };

    const stateServicePageRedirectOptions = {
      serviceTypeId: serviceCMS?.serviceId,
      locationType: 'StateOrProvince',
      StateAbbreviation: stateAbbreviation,
    };

    if (servicePageType === INDIVIDUAL_SERVICE_PAGE) {
      if (serviceSlug === 'kids-party-entertainment') {
        const result = yield call(
          [locationsApiClient, locationsApiClient.getCities],
          kidsPartyCitiesQueryOptions
        );
        statesAndCities = transformToStatesAndCitiesList(result, serviceSlug, true);
      } else {
        const result = yield call(
          [searchLookupsApiClient, searchLookupsApiClient.getSearchLookups],
          statesAndCitiesQueryOptions
        );
        statesAndCities = transformToStatesAndCitiesList(result, serviceSlug);
      }
    } else if (
      servicePageType === STATE_SERVICE_PAGE ||
      servicePageType === CITY_BY_LETTER_SERVICE_PAGE
    ) {
      if (serviceSlug === 'kids-party-entertainment') {
        redirect = '/gigkids';
      } else {
        const redirectData = yield call(
          [searchLookupsApiClient, searchLookupsApiClient.getSearchLookups],
          stateServicePageRedirectOptions
        );
        redirect = `/search/${redirectData[0].searchString}#state-cities`;
      }
    }

    yield put({
      type: GET_SERVICE_SUCCESS,
      servicePageKey,
      serviceCMS,
      statesAndCities,
      redirect,
    });
  } catch (error) {
    yield put({
      type: GET_SERVICE_FAILURE,
      error,
      servicePageKey,
    });
  }
}

export function* servicesSaga() {
  yield all([takeEvery(GET_SERVICE_REQUEST, getServiceAsync)]);
}
