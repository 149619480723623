import getConfig from 'next/config';

const gmDomain = getConfig().publicRuntimeConfig.gmApiUrl;

export const buildGMSearchLink = () => gmDomain;

export const buildGmStateSearchLink = ({ searchLocation, serviceType } = {}) => {
  if (!searchLocation || !serviceType) {
    throw new Error('buildGmStateSearchAttributes requires searchLocation and serviceType');
  }

  return `/search/${serviceType.fileName}-${searchLocation.stateOrProvince.fileName}`;
};

export const buildGmServiceTypeSearchLink = ({ serviceType, searchCategoryId }) => {
  if (!serviceType) {
    throw new Error('buildGmServiceTypeSearchAttributes requires serviceType');
  }

  if (serviceType.isSingleSearchPage && serviceType.id === searchCategoryId) {
    return `/search/${serviceType.fileName}`;
  }

  return `/services/${serviceType.fileName}`;
};

export const buildGmKidsSearchLink = ({ searchLocation, serviceType }) => {
  if (!searchLocation || !serviceType) {
    throw new Error('buildGmKidsSearchAttributes requires searchLocation and serviceType');
  }

  return `${gmDomain}/kids-party-entertainment/${searchLocation.fileName}?categoryId=${
    serviceType.id
  }`;
};

export const buildGmWeddingsSearchLink = ({ searchLocation, serviceType }) => {
  if (!searchLocation || !serviceType) {
    throw new Error('buildGmWeddingsSearchAttributes requires searchLocation and serviceType');
  }

  return `${gmDomain}/wedding-entertainment/${searchLocation.fileName}?categoryId=${
    serviceType.id
  }`;
};
