const ADVICE = 'advice';
const INSPIRATION = 'inspiration';
const MUSIC = 'music';
const PARTY_PLAYLISTS = 'party playlists';
const PARTY_THEMES = 'party themes';
const PLANNING = 'planning';
const PRACTICAL_TIPS = 'practical tips';
const REAL_EVENTS = 'real events';
const TIPS = 'tips';

const ARTICLE_TAGS = [
  ADVICE,
  INSPIRATION,
  MUSIC,
  PARTY_PLAYLISTS,
  PARTY_THEMES,
  PLANNING,
  PRACTICAL_TIPS,
  REAL_EVENTS,
  TIPS,
];

export default ARTICLE_TAGS;
