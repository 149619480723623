import store from 'store';
import { gmConfig } from 'helpers/config';

const prefixKey = (key) => `${gmConfig.cookie.prefix}${key}`;

module.exports = {
  get: (key) => store.get(prefixKey(key)),
  set: (key, value) => store.set(prefixKey(key), value),
  remove: (key) => store.remove(prefixKey(key)),
};
