import api from 'api/profiles';
import searchApi from 'api/search';
import isempty from 'lodash.isempty';
import { ERROR, LOADED, LOADING } from 'state/status';
import mergeState from 'state/utils/mergeState';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import isPremiumProfile from 'helpers/isPremiumProfile';
import getRandomSeed from 'helpers/getRandomSeed';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  entities: {},
  errors: {},
  fetchStatus: {},
  premiumProfiles: {},
  reviews: {},
  reviewsFetchStatus: {},
  reviewsError: {},
  upcomingEvents: {},
  upcomingEventsFetchStatus: {},
  upcomingEventsError: {},
  pastEvents: {},
  pastEventsFetchStatus: {},
  pastEventsError: {},
  photos: {},
  photosFetchStatus: {},
  photosError: {},
};

const getReviewsKey = (directory, page = null, widget = null, sortType = null) => {
  let queryString = null;

  if (page && widget) {
    queryString = `page=${page}&widget=${widget}`;
  } else if (page) {
    queryString = `page=${page}`;
  } else if (widget) {
    queryString = `widget=${widget}`;
  }

  if (sortType) {
    queryString = queryString ? `${queryString}&sort=${sortType}` : `sort=${sortType}`;
  }

  if (queryString) {
    return `${directory}/feedback?${queryString}`;
  }
  return directory;
};

export const GET_PROFILE_REQUEST = 'the-bash/profiles/GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'the-bash/profiles/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'the-bash/profiles/GET_PROFILE_FAILURE';
export const GET_PROFILE_NOT_FOUND = 'the-bash/profiles/GET_PROFILE_NOT_FOUND';

export const PROFILE_SORT_REVIEWS_REQUEST = 'the-bash/profiles/PROFILE_SORT_REVIEWS_REQUEST';
export const PROFILE_SORT_REVIEWS_SUCCESS = 'the-bash/profiles/PROFILE_SORT_REVIEWS_SUCCESS';
export const PROFILE_SORT_REVIEWS_FAILURE = 'the-bash/profiles/PROFILE_SORT_REVIEWS_FAILURE';

export const PROFILE_LOAD_MORE_REVIEWS_REQUEST =
  'the-bash/profiles/PROFILE_LOAD_MORE_REVIEWS_REQUEST';
export const PROFILE_LOAD_MORE_REVIEWS_SUCCESS =
  'the-bash/profiles/PROFILE_LOAD_MORE_REVIEWS_SUCCESS';
export const PROFILE_LOAD_MORE_REVIEWS_FAILURE =
  'the-bash/profiles/PROFILE_LOAD_MORE_REVIEWS_FAILURE';

export const LOAD_MORE_UPCOMING_EVENTS_REQUEST =
  'the-bash/profiles/LOAD_MORE_UPCOMING_EVENTS_REQUEST';
export const LOAD_MORE_UPCOMING_EVENTS_SUCCESS =
  'the-bash/profiles/LOAD_MORE_UPCOMING_EVENTS_SUCCESS';
export const LOAD_MORE_UPCOMING_EVENTS_FAILURE =
  'the-bash/profiles/LOAD_MORE_UPCOMING_EVENTS_FAILURE';

export const LOAD_MORE_PAST_EVENTS_REQUEST = 'the-bash/profiles/LOAD_MORE_PAST_EVENTS_REQUEST';
export const LOAD_MORE_PAST_EVENTS_SUCCESS = 'the-bash/profiles/LOAD_MORE_PAST_EVENTS_SUCCESS';
export const LOAD_MORE_PAST_EVENTS_FAILURE = 'the-bash/profiles/LOAD_MORE_PAST_EVENTS_FAILURE';

export const PROFILE_LOAD_MORE_PHOTOS_REQUEST =
  'the-bash/profiles/PROFILE_LOAD_MORE_PHOTOS_REQUEST';
export const PROFILE_LOAD_MORE_PHOTOS_SUCCESS =
  'the-bash/profiles/PROFILE_LOAD_MORE_PHOTOS_SUCCESS';
export const PROFILE_LOAD_MORE_PHOTOS_FAILURE =
  'the-bash/profiles/PROFILE_LOAD_MORE_PHOTOS_FAILURE';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      const { profiles = initialState } = action.payload;
      return mergeState(initialState, state, profiles);
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.directory]: LOADING,
        },
      };
    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.directory]: LOADED,
        },
        entities: {
          ...state.entities,
          [action.directory]: action.profile,
        },
        errors: {},
        reviews: {
          ...state.reviews,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]:
            action.profile.feedback,
        },
        reviewsFetchStatus: {
          ...state.reviewsFetchStatus,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]: LOADED,
        },
        upcomingEvents: {
          ...state.upcomingEvents,
          [action.directory]: action.profile.upcomingEvents,
        },
        upcomingEventsFetchStatus: {
          ...state.upcomingEventsFetchStatus,
          [action.directory]: LOADED,
        },
        pastEvents: {
          ...state.pastEvents,
          [action.directory]: action.profile.pastEvents,
        },
        pastEventsFetchStatus: {
          ...state.pastEventsFetchStatus,
          [action.directory]: LOADED,
        },
        photos: {
          ...state.photos,
          [action.directory]: action.profile.photos,
        },
        photosFetchStatus: {
          ...state.photosFetchStatus,
          [action.directory]: LOADED,
        },
      };
    }
    case GET_PROFILE_FAILURE: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.directory]: ERROR,
        },
        errors: {
          ...state.errors,
          [action.directory]: action.error,
        },
      };
    }
    case GET_PROFILE_NOT_FOUND: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.directory]: ERROR,
        },
        errors: {
          ...state.errors,
          [action.directory]: {
            statusCode: 404,
          },
        },
      };
    }
    case PROFILE_LOAD_MORE_REVIEWS_REQUEST: {
      return {
        ...state,
        reviewsFetchStatus: {
          ...state.reviewsFetchStatus,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]: LOADING,
        },
      };
    }
    case PROFILE_SORT_REVIEWS_REQUEST: {
      return {
        ...state,
        reviewsFetchStatus: {
          ...state.reviewsFetchStatus,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]: LOADING,
        },
      };
    }
    case LOAD_MORE_PAST_EVENTS_REQUEST: {
      return {
        ...state,
        pastEventsFetchStatus: {
          ...state.pastEventsFetchStatus,
          [action.directory]: LOADING,
        },
      };
    }
    case PROFILE_LOAD_MORE_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]: [
            ...(state.reviews[
              getReviewsKey(action.directory, action.page, action.widget, action.sortType)
            ] || []),
            ...action.reviews,
          ],
        },
        reviewsFetchStatus: {
          ...state.reviewsFetchStatus,
          [getReviewsKey(action.directory, action.page)]: LOADED,
        },
      };
    }
    case PROFILE_SORT_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]:
            action.reviews,
        },
        reviewsFetchStatus: {
          ...state.reviewsFetchStatus,
          [getReviewsKey(action.directory, action.page)]: LOADED,
        },
      };
    }
    case LOAD_MORE_PAST_EVENTS_SUCCESS: {
      return {
        ...state,
        pastEvents: {
          ...state.pastEvents,
          [action.directory]: [...state.pastEvents[action.directory], ...action.pastEvents],
        },
        pastEventsFetchStatus: {
          ...state.pastEventsFetchStatus,
          [action.directory]: LOADED,
        },
      };
    }
    case PROFILE_LOAD_MORE_REVIEWS_FAILURE: {
      return {
        ...state,
        reviewsError: {
          ...state.reviewsError,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]:
            action.error,
        },
        reviewsFetchStatus: {
          ...state.reviewsFetchStatus,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]: ERROR,
        },
      };
    }
    case PROFILE_SORT_REVIEWS_FAILURE: {
      return {
        ...state,
        reviewsError: {
          ...state.reviewsError,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]:
            action.error,
        },
        reviewsFetchStatus: {
          ...state.reviewsFetchStatus,
          [getReviewsKey(action.directory, action.page, action.widget, action.sortType)]: ERROR,
        },
      };
    }
    case LOAD_MORE_PAST_EVENTS_FAILURE: {
      return {
        ...state,
        pastEventsError: {
          ...state.pastEventsError,
          [action.directory]: action.error,
        },
        pastEventsFetchStatus: {
          ...state.pastEventsFetchStatus,
          [action.directory]: ERROR,
        },
      };
    }
    case LOAD_MORE_UPCOMING_EVENTS_REQUEST: {
      return {
        ...state,
        upcomingEventsFetchStatus: {
          ...state.upcomingEventsFetchStatus,
          [action.directory]: LOADING,
        },
      };
    }
    case LOAD_MORE_UPCOMING_EVENTS_SUCCESS: {
      return {
        ...state,
        upcomingEvents: {
          ...state.upcomingEvents,
          [action.directory]: [...state.upcomingEvents[action.directory], ...action.upcomingEvents],
        },
        upcomingEventsFetchStatus: {
          ...state.pastEventsFetchStatus,
          [action.directory]: LOADED,
        },
      };
    }
    case LOAD_MORE_UPCOMING_EVENTS_FAILURE: {
      return {
        ...state,
        upcomingEventsError: {
          ...state.upcomingEventsError,
          [action.directory]: action.error,
        },
        upcomingEventsFetchStatus: {
          ...state.upcomingEventsFetchStatus,
          [action.directory]: ERROR,
        },
      };
    }
    case PROFILE_LOAD_MORE_PHOTOS_REQUEST: {
      return {
        ...state,
        photosFetchStatus: {
          ...state.photosFetchStatus,
          [action.directory]: LOADING,
        },
      };
    }
    case PROFILE_LOAD_MORE_PHOTOS_SUCCESS: {
      return {
        ...state,
        photos: {
          ...state.photos,
          [action.directory]: [...state.photos[action.directory], ...action.photos],
        },
        photosFetchStatus: {
          ...state.photosFetchStatus,
          [action.directory]: LOADED,
        },
      };
    }
    case PROFILE_LOAD_MORE_PHOTOS_FAILURE: {
      return {
        ...state,
        photosError: {
          ...state.photosError,
          [action.directory]: action.error,
        },
        photosFetchStatus: {
          ...state.photosFetchStatus,
          [action.directory]: ERROR,
        },
      };
    }
    default:
      return state;
  }
}

// Selectors
export const selectProfileError = (state, directory) => state.profiles.errors[directory];
export const selectProfileFetchStatus = (state, directory) => state.profiles.fetchStatus[directory];

export const selectProfileHasError = (state, directory) =>
  selectProfileFetchStatus(state, directory) === ERROR;
export const selectProfile = (state, directory) => state.profiles.entities[directory] || null;

export const selectProfileIsLoaded = (state, directory) =>
  selectProfileFetchStatus(state, directory) === LOADED;

export const selectReviews = (state, directory, page = null, widget = null, sortType = null) =>
  state.profiles.reviews[getReviewsKey(directory, page, widget, sortType)];
export const selectLoadMoreReviewsFetchStatus = (state, directory, page = null, widget = null) =>
  state.profiles.reviewsFetchStatus[getReviewsKey(directory, page, widget)];
export const selectLoadMoreReviewsIsLoading = (state, directory, page = null, widget = null) =>
  selectLoadMoreReviewsFetchStatus(state, directory, page, widget) === LOADING;

export const selectUpcomingEvents = (state, directory) => state.profiles.upcomingEvents[directory];
export const selectUpcomingEventsFetchStatus = (state, directory) =>
  state.profiles.upcomingEventsFetchStatus[directory];
export const selectUpcomingEventsIsLoading = (state, directory) =>
  selectUpcomingEventsFetchStatus(state, directory) === LOADING;

export const selectPastEvents = (state, directory) => state.profiles.pastEvents[directory];
export const selectPastEventsFetchStatus = (state, directory) =>
  state.profiles.pastEventsFetchStatus[directory];
export const selectPastEventsIsLoading = (state, directory) =>
  selectPastEventsFetchStatus(state, directory) === LOADING;

export const selectPhotos = (state, directory) => state.profiles.photos[directory] || [];
export const selectPhotosFetchStatus = (state, directory) =>
  state.profiles.photosFetchStatus[directory];
export const selectPhotosIsLoading = (state, directory) =>
  selectPhotosFetchStatus(state, directory) === LOADING;

// Actions
export const loadMoreReviews = ({
  directory,
  skip,
  take,
  page = null,
  widget = null,
  sortType = null,
}) => ({
  type: PROFILE_LOAD_MORE_REVIEWS_REQUEST,
  directory,
  skip,
  take,
  page,
  widget,
  sortType,
});

export function* loadMoreReviewsAsync(action) {
  const { skip, take, directory, page, widget, sortType } = action;
  try {
    const reviews = yield call([api, api.getReviews], directory, skip, take, sortType);
    yield put({
      type: PROFILE_LOAD_MORE_REVIEWS_SUCCESS,
      reviews,
      directory,
      page,
      widget,
      sortType,
    });
  } catch (error) {
    yield put({
      type: PROFILE_LOAD_MORE_REVIEWS_FAILURE,
      error,
      directory,
      page,
      widget,
      sortType,
    });
  }
}

export const sortReviews = ({
  directory,
  skip,
  take,
  page = null,
  widget = null,
  sortType = null,
}) => ({
  type: PROFILE_SORT_REVIEWS_REQUEST,
  directory,
  skip,
  take,
  page,
  widget,
  sortType,
});

export function* sortReviewsAsync(action) {
  const { skip, take, directory, page, widget, sortType } = action;
  try {
    const reviews = yield call([api, api.getReviews], directory, skip, take, sortType);
    yield put({
      type: PROFILE_SORT_REVIEWS_SUCCESS,
      reviews,
      directory,
      page,
      widget,
      sortType,
    });
  } catch (error) {
    yield put({
      type: PROFILE_SORT_REVIEWS_FAILURE,
      error,
      directory,
      page,
      widget,
      sortType,
    });
  }
}

export const loadMorePastEvents = ({ directory, memberID, skip, take }) => ({
  type: LOAD_MORE_PAST_EVENTS_REQUEST,
  directory,
  memberID,
  skip,
  take,
});

export function* loadMorePastEventsAsync(action) {
  const { skip, take, memberID, directory } = action;
  try {
    const pastEvents = yield call([api, api.getPastEvents], memberID, skip, take);
    yield put({
      type: LOAD_MORE_PAST_EVENTS_SUCCESS,
      pastEvents,
      directory,
    });
  } catch (error) {
    yield put({
      type: LOAD_MORE_PAST_EVENTS_FAILURE,
      error,
      directory,
    });
  }
}

export const loadMoreUpcomingEvents = ({ directory, memberID, skip, take }) => ({
  type: LOAD_MORE_UPCOMING_EVENTS_REQUEST,
  directory,
  memberID,
  skip,
  take,
});

export function* loadMoreUpcomingEventsAsync(action) {
  const { skip, take, memberID, directory } = action;
  try {
    const upcomingEvents = yield call([api, api.getUpcomingEvents], memberID, skip, take);
    yield put({
      type: LOAD_MORE_UPCOMING_EVENTS_SUCCESS,
      upcomingEvents,
      directory,
    });
  } catch (error) {
    yield put({
      type: LOAD_MORE_UPCOMING_EVENTS_FAILURE,
      error,
      directory,
    });
  }
}

export const getProfile = (directory) => ({ type: GET_PROFILE_REQUEST, directory });

export function* getProfileAsync(action) {
  const { directory } = action;

  let profile = yield select(selectProfile, directory);
  if (!profile?.id) {
    try {
      profile = yield call([api, api.getProfile], action.directory);

      if (isempty(profile)) {
        yield put({
          type: GET_PROFILE_NOT_FOUND,
          directory,
        });
      } else {
        if (profile.venue) {
          profile.isPremium = isPremiumProfile(profile);

          if (!profile.isPremium && profile.memberLevel !== -9) {
            const response = yield call([searchApi, searchApi.getVendors], 0, 4, {
              slug: `venue-${profile.venue.market.fileName}`,
              excludeNonPaidVenues: true,
              overrideSortSeed: getRandomSeed(),
            });

            profile.premiumProfiles = response.searchResults;
            profile.isPremium = isPremiumProfile(profile);
          }
        }

        yield put({
          type: GET_PROFILE_SUCCESS,
          profile,
          directory,
        });
      }
    } catch (error) {
      yield put({
        type: GET_PROFILE_FAILURE,
        error,
        directory,
      });
    }
  }
}

export const loadMorePhotos = (directory, quantity) => ({
  type: PROFILE_LOAD_MORE_PHOTOS_REQUEST,
  directory,
  quantity,
});

export function* loadMorePhotosAsync(action) {
  const { directory, quantity } = action;

  const profile = yield select(selectProfile, directory);
  const profilePhotos = yield select(selectPhotos, directory);

  try {
    const photos = yield call(
      [api, api.getProfilePhotos],
      profile.id,
      profilePhotos.length,
      quantity
    );
    yield put({
      type: PROFILE_LOAD_MORE_PHOTOS_SUCCESS,
      directory,
      photos,
    });
  } catch (error) {
    yield put({
      type: PROFILE_LOAD_MORE_PHOTOS_FAILURE,
      error,
      directory,
    });
  }
}

export function* profilesSaga() {
  yield all([takeEvery(GET_PROFILE_REQUEST, getProfileAsync)]);
  yield all([takeEvery(PROFILE_LOAD_MORE_REVIEWS_REQUEST, loadMoreReviewsAsync)]);
  yield all([takeEvery(PROFILE_SORT_REVIEWS_REQUEST, sortReviewsAsync)]);
  yield all([takeEvery(LOAD_MORE_PAST_EVENTS_REQUEST, loadMorePastEventsAsync)]);
  yield all([takeEvery(LOAD_MORE_UPCOMING_EVENTS_REQUEST, loadMoreUpcomingEventsAsync)]);
  yield all([takeEvery(PROFILE_LOAD_MORE_PHOTOS_REQUEST, loadMorePhotosAsync)]);
}
