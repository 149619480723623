import { combineReducers } from 'redux';

import {
  localStorageReducer,
  locationsReducer,
  searchLookupsReducer,
  pageLevelBasePropertiesReducer,
} from '@tkww/the-bash-frontend';

import articles from '../modules/articles';
import events from '../modules/events';
import profiles from '../modules/profiles';
import search from '../modules/search';
import serviceAttributes from '../modules/serviceAttributes';
import services from '../modules/services';
import themes from '../modules/themes';
import viewport from '../modules/viewport';
import venueMarkets from '../modules/venueMarkets';

export default combineReducers({
  articles,
  events,
  localStorage: localStorageReducer,
  locations: locationsReducer,
  pageLevelBaseProperties: pageLevelBasePropertiesReducer,
  profiles,
  search,
  searchLookups: searchLookupsReducer,
  serviceAttributes,
  services,
  themes,
  viewport,
  venueMarkets,
});
