export const setExperiment = (experiment) => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('set', 'exp', experiment);
  }
};

export const unsetExperiment = () => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('set', 'exp', '');
  }
};
