import createCache from '@emotion/cache';

/**
 * Cached configuration used for css styles from Material UI, while using emotion as the styling
 * engine.
 */
const createEmotionCache = () => {
  let insertionPoint;
  const isBrowser = typeof document !== 'undefined';

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
    insertionPoint = emotionInsertionPoint || undefined;
  }

  return createCache({ key: 'mui-style', insertionPoint });
};

export default createEmotionCache;
