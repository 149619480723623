import { CANADA_PROVINCES, CANADA } from 'constants/searchLookups';

// transform data into list of cities or counties for state and city by letter service pages
export const transformToLocationsList = (stateLocations, isGigKids = false, suffix = '') =>
  stateLocations.map((item) => {
    // handle array of SearchLookups or Locations
    const location = item.location ? item.location : item;
    return {
      title: suffix ? `${location?.name} ${suffix}` : location?.name,
      href: isGigKids
        ? `/kids-party-entertainment/${location.fileName}`
        : `/search/${item.searchString}`,
      state: location.stateOrProvince,
    };
  });

// transform data into list of state and cities for individual service pages
export const transformToStatesAndCitiesList = (
  serviceLocations,
  serviceSlug,
  isGigKids = false
) => {
  const statesMap = serviceLocations.reduce((result, currentValue) => {
    const resultMap = result;
    const location = currentValue.location ? currentValue.location : currentValue;
    const {
      displayName,
      fileName: stateFileName,
      name,
      abbreviation: stateAbbreviation,
    } = location.stateOrProvince;
    const isCanada = CANADA_PROVINCES.includes(name.toLowerCase());
    const state = isCanada ? CANADA : name;
    const { name: city, fileName: cityFileName } = location;

    const cityLink = isGigKids
      ? `/kids-party-entertainment/${cityFileName}`
      : `/search/${currentValue.searchString}`;

    const seeMoreLink = `/services/${serviceSlug}/${stateAbbreviation.toLowerCase()}`;

    const stateSearchLink = isGigKids ? '' : `/search/${serviceSlug}-${stateFileName}`;

    if (!resultMap[state]) {
      resultMap[state] = {
        title: isCanada ? CANADA : displayName,
        stateAbbreviation: isCanada ? CANADA : stateAbbreviation,
        items: [],
      };

      if (!isCanada) {
        resultMap[state] = {
          ...resultMap[state],
          searchCategoryByState: seeMoreLink, // 'see more {state} locations' link
          href: stateSearchLink, // state name header link
        };
      }
    }

    resultMap[state].items.push({
      title: city,
      href: cityLink,
    });

    return resultMap;
  }, {});

  const states = [];
  Object.keys(statesMap).forEach((state) => {
    states.push(statesMap[state]);
  });

  states.sort((a, b) => {
    if (a.title === CANADA) return 1;
    if (b.title === CANADA) return -1;
    if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
    if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
    return 0;
  });

  return states;
};
