import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { selectIsMobile } from 'state/modules/viewport';
import { useSelector } from 'react-redux';

const ModalContext = React.createContext();
const Dialog = dynamic(() => import('components/Dialog'));

export const ModalProvider = props => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(<div />);

  return <ModalContext.Provider value={{ open, setOpen, content, setContent }} {...props} />;
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext must be used within a ModalProvider`);
  }
  return context;
};

const freezeBackgroundScroll = () => {
  document.body.style.position = 'fixed';
  document.body.style.top = `-${window.scrollY}px`;
};

const unfreezeBackgroundScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};

export const Modal = () => {
  const { open, setOpen, content } = useModalContext();
  const isMobile = useSelector(selectIsMobile);

  useEffect(
    () => {
      if (isMobile) {
        if (open) {
          freezeBackgroundScroll();
        } else {
          unfreezeBackgroundScroll();
        }
      }
    },
    [open, isMobile]
  );

  return open ? (
    <Dialog open={open} isMobile={isMobile} onClose={() => setOpen(false)}>
      {content}
    </Dialog>
  ) : null;
};
