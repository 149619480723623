const _normalizeSearchResult = ({
  bookingBadgeTitle,
  bookingsBadgeUrl,
  eventTypeOptOutIds,
  numberOfAudioTracks,
  numberOfOffers,
  mixedSearchScore,
  payRangeHigh,
  primaryServiceFileName,
  primaryServiceParentID,
  profilePhotoCropBottom,
  profilePhotoCropLeft,
  profilePhotoCropRight,
  profilePhotoCropTop,
  profilePhotoRotation,
  ratingString,
  requestFormID,
  searchThumbnailCropBottom,
  searchThumbnailCropLeft,
  searchThumbnailCropRight,
  searchThumbnailCropTop,
  searchThumbnailRotation,
  sponsoredListingCropBottom,
  sponsoredListingCropLeft,
  sponsoredListingCropRight,
  sponsoredListingCropTop,
  sponsoredListingFileName,
  mainPhotoUrl,
  genreRelatedInstrumentServiceTypeIds,
  overallServiceParentID,
  photoMediaApiGuid,
  providedGenreServiceTypeIds,
  providedServiceTypeIds,
  startDate,
  ...rest
}) => rest;

export const normalizeSearchResults = (results = []) => {
  if (results) return results.map(_normalizeSearchResult);
  return [];
};

const _normalizeLocation = (location) => {
  // Make sure search is not for global obscure categories
  if (!location) return null;
  const { geolocation, ...rest } = location;
  return rest;
};

// normalizeSearchInfo removes properties that are not being used by the search page
export const normalizeSearchInfo = ({
  earliestSponsoredListingExpirationDate,
  frequentlyAskedQuestions,
  sponsoredListing,
  locationId,
  metropolitanDivisionId,
  mosaicQuestions,
  reviews,
  ...rest
}) => ({
  ...rest,
  location: _normalizeLocation(rest.location),
});
