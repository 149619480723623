import isempty from 'lodash.isempty';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from 'api/search';
import { getIsSearchById } from 'helpers/search';
import mergeState from 'state/utils/mergeState';
import { ERROR, LOADED, LOADING } from 'state/status';
import { OBSCURE_SEARCH_RESULT_SETS } from 'constants/Search';
import { normalizeSearchInfo, normalizeSearchResults } from 'state/utils/searchResults';
import { HYDRATE } from 'next-redux-wrapper';
import { searchLookupsApiFactory } from '@tkww/the-bash-frontend';
import { gmApiUrl } from 'helpers/config';
import { BY_LETTER_LOCATION_TYPE_CITY } from 'constants/Services';
import { getLocationStartsWithGroupingQueryInput } from 'helpers/search/stateCityLinks';

export const initialState = {
  canonicalUrls: {},
  entities: {},
  errors: {},
  fetchStatus: {},
  redirects: {},
  searchCopy: {},
  searchInfo: {},
  totals: {},
  fullSearchTotals: {},
  sponsoredListings: {},
  otherVendorsResults: {},
  landingVendorsNearby: {},
  stateCityLinks: {},
};

export const SEARCH_REQUEST = 'the-bash/search/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'the-bash/search/SEARCH_SUCCESS';
export const SEARCH_REDIRECT = 'the-bash/search/SEARCH_REDIRECT';
export const SEARCH_FAILURE = 'the-bash/search/SEARCH_FAILURE';
export const SEARCH_NOT_FOUND = 'the-bash/search/SEARCH_NOT_FOUND';
export const SEARCH_LOAD_MORE_REQUEST = 'the-bash/search/SEARCH_LOAD_MORE_REQUEST';
export const SEARCH_LOAD_MORE_SUCCESS = 'the-bash/search/SEARCH_LOAD_MORE_SUCCESS';
export const SEARCH_LOAD_MORE_FAILURE = 'the-bash/search/SEARCH_LOAD_MORE_FAILURE';
export const SEARCH_LOAD_MORE_OTHER_VENDORS_SUCCESS =
  'the-bash/search/SEARCH_LOAD_MORE_OTHER_VENDORS_SUCCESS';
export const SEARCH_LOAD_MORE_OTHER_VENDORS_FAILURE =
  'the-bash/search/SEARCH_LOAD_MORE_OTHER_VENDORS_FAILURE';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      const { search = initialState } = action.payload;

      return mergeState(initialState, state, search);
    case SEARCH_REQUEST:
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.searchTerm]: LOADING,
        },
      };
    case SEARCH_REDIRECT: {
      return {
        ...state,
        redirects: {
          ...state.redirects,
          [action.searchTerm]: action.result,
        },
      };
    }
    case SEARCH_SUCCESS: {
      const otherVendorsResults = action.maintainOtherVendorResults
        ? state.otherVendorsResults[action.searchTerm]
        : action.otherVendorsResults;

      return {
        ...state,
        canonicalUrls: {
          ...state.canonicalUrls,
          [action.searchTerm]: action.canonicalUrl,
        },
        fetchStatus: {
          ...state.fetchStatus,
          [action.searchTerm]: LOADED,
        },
        entities: {
          ...state.entities,
          [action.searchTerm]: normalizeSearchResults(action.searchResults),
        },
        searchCopy: {
          ...state.searchCopy,
          [action.searchTerm]: action.searchCopy,
        },
        searchInfo: {
          ...state.searchInfo,
          [action.searchTerm]: normalizeSearchInfo(action.searchInfo),
        },
        sponsoredListings: {
          ...state.sponsoredListings,
          [action.searchTerm]: action.sponsoredListings,
        },
        totals: {
          ...state.totals,
          [action.searchTerm]: action.totalResults,
        },
        fullSearchTotals: {
          ...state.fullSearchTotals,
          ...(action.totalFullSearchResults !== undefined
            ? { [action.searchTerm]: action.totalFullSearchResults }
            : {}),
        },
        otherVendorsResults: {
          ...state.otherVendorsResults,
          ...(otherVendorsResults !== undefined
            ? { [action.searchTerm]: otherVendorsResults }
            : {}),
        },
        landingVendorsNearby: {
          ...state.landingVendorsNearby,
          ...(action.landingVendorsNearby !== undefined
            ? { [action.searchTerm]: action.landingVendorsNearby }
            : {}),
        },
        stateCityLinks: {
          ...state.stateCityLinks,
          [action.searchTerm]: action.stateCityLinks,
        },
      };
    }
    case SEARCH_LOAD_MORE_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.searchTerm]: LOADING,
        },
      };
    }
    case SEARCH_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.searchTerm]: LOADED,
        },
        entities: {
          ...state.entities,
          [action.searchTerm]: [...state.entities[action.searchTerm], ...action.searchResults],
        },
      };
    }
    case SEARCH_LOAD_MORE_OTHER_VENDORS_SUCCESS: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.searchTerm]: LOADED,
        },
        otherVendorsResults: {
          ...state.otherVendorsResults,
          [action.searchTerm]: {
            ...state.otherVendorsResults[action.searchTerm],
            vendors: [
              ...state.otherVendorsResults[action.searchTerm].vendors,
              ...action.searchResults,
            ],
          },
        },
      };
    }
    case SEARCH_LOAD_MORE_FAILURE:
    case SEARCH_LOAD_MORE_OTHER_VENDORS_FAILURE:
    case SEARCH_FAILURE: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.searchTerm]: ERROR,
        },
        errors: {
          ...state.errors,
          [action.searchTerm]: action.error,
        },
      };
    }
    case SEARCH_NOT_FOUND: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [action.searchTerm]: ERROR,
        },
        errors: {
          ...state.errors,
          [action.searchTerm]: {
            statusCode: 404,
          },
        },
      };
    }
    default:
      return state;
  }
}

// Selectors

export const selectSearchCopy = (state, searchTerm) => state.search.searchCopy[searchTerm] || null;

export const selectSearchError = (state, searchTerm) => state.search.errors[searchTerm];

export const selectSearchFetchStatus = (state, searchTerm) => state.search.fetchStatus[searchTerm];

export const selectSearchInfo = (state, searchTerm) => state.search.searchInfo[searchTerm] || null;

export const selectSearchLocation = (state, searchTerm) =>
  selectSearchInfo(state, searchTerm)?.location || null;

export const selectSearchHasError = (state, searchTerm) =>
  selectSearchFetchStatus(state, searchTerm) === ERROR;

export const selectSearchRedirect = (state, searchTerm) =>
  state.search.redirects[searchTerm] || null;

export const selectSearchResults = (state, searchTerm) => state.search.entities[searchTerm] || [];

export const selectOtherVendorsResults = (state, searchTerm) =>
  state.search.otherVendorsResults[searchTerm] || null;

export const selectLandingVendorsNearby = (state, searchTerm) =>
  state.search.landingVendorsNearby[searchTerm] || null;

export const selectSearchServiceType = (state, searchTerm) =>
  selectSearchInfo(state, searchTerm)?.serviceType;

export const selectSearchTotal = (state, searchTerm) =>
  state.search.totals[searchTerm] || state.search.totals[searchTerm] === 0
    ? state.search.totals[searchTerm]
    : null;

export const selectFullSearchTotal = (state, searchTerm) =>
  state.search.fullSearchTotals[searchTerm] || null;

export const selectSponsoredListings = (state, searchTerm) =>
  state.search.sponsoredListings[searchTerm] || [];

export const selectVendorSpotlights = (state, searchTerm) =>
  selectSponsoredListings(state, searchTerm).filter((s) => s.memberLevel >= -2 && s.spotlightId) ||
  [];

export const selectSearchIsLoaded = (state, searchTerm) =>
  selectSearchFetchStatus(state, searchTerm) === LOADED;

export const selectSearchIsLoading = (state, searchTerm) =>
  selectSearchFetchStatus(state, searchTerm) === LOADING;

export const selectSearchResultCount = (state, searchTerm) =>
  selectSearchResults(state, searchTerm).length;

export const selectSearchCanonicalUrl = (state, searchTerm) =>
  state.search.canonicalUrls[searchTerm] || null;

export const selectStateCityLinks = (state, searchTerm) =>
  state.search.stateCityLinks[searchTerm] || [];

// APIs

const searchLookupsApiClient = searchLookupsApiFactory(gmApiUrl);

// Queries

export const search = ({ query, skip, take, searchTerm, onComplete = null }) => ({
  type: SEARCH_REQUEST,
  query,
  skip,
  take,
  searchTerm,
  onComplete,
});

export const loadMore = ({ query, skip, take, searchTerm, obscureSearchResultSet }) => ({
  type: SEARCH_LOAD_MORE_REQUEST,
  query,
  skip,
  take,
  searchTerm,
  obscureSearchResultSet,
});

const cityByLetterQueryOptions = (serviceTypeId, stateAbbreviation, letterGrouping) => ({
  serviceTypeId,
  locationType: BY_LETTER_LOCATION_TYPE_CITY,
  StateAbbreviation: stateAbbreviation,
  locationStartsWithGrouping: getLocationStartsWithGroupingQueryInput(letterGrouping),
  isAValidSearch: true,
});

// Sagas

export function* loadMoreAsync(action) {
  const { query, skip, take, searchTerm, obscureSearchResultSet } = action;
  const isOtherVendors = obscureSearchResultSet === OBSCURE_SEARCH_RESULT_SETS.RELATED_VENDORS;
  try {
    const { searchResults } = yield call([api, api.getVendors], skip, take, query);
    yield put({
      type: isOtherVendors ? SEARCH_LOAD_MORE_OTHER_VENDORS_SUCCESS : SEARCH_LOAD_MORE_SUCCESS,
      searchResults,
      searchTerm,
    });
  } catch (error) {
    yield put({
      type: isOtherVendors ? SEARCH_LOAD_MORE_OTHER_VENDORS_FAILURE : SEARCH_LOAD_MORE_FAILURE,
      error,
      searchTerm,
    });
  }
}

export function* searchAsync(action) {
  const { query, skip, take, searchTerm, onComplete } = action;
  const { obscureSearchResultSet, slug } = query;

  try {
    const isSearchById = getIsSearchById(query);

    let result;
    let searchCopy;
    let stateCityLinks;

    if (isSearchById) {
      // Search by ID redirects to canonical url based on the search lookup
      // Set take=0 since there is no longer a need to retrieve search results
      result = yield call([api, api.getVendors], skip, 0, query);
    } else {
      [result, searchCopy, stateCityLinks] = yield all([
        call([api, api.getVendors], skip, take, query),
        call([api, api.getSearchCopy], query),
        call([api, api.getSearchLookupForCityGroups], slug),
      ]);
    }
    stateCityLinks = isempty(stateCityLinks) ? [] : stateCityLinks;

    if (onComplete) {
      yield call(onComplete, result);
    }

    if (isempty(result)) {
      yield put({
        type: SEARCH_NOT_FOUND,
        searchTerm,
      });
    } else if ((isSearchById && result.searchInfo) || result.redirectUrl) {
      yield put({
        type: SEARCH_REDIRECT,
        result,
        searchTerm,
      });
    } else {
      const { searchInfo, relatedVendorResults, relatedVendorTotalResults, version } = result;

      // This needs to preserve the other vendors when filtering down the primary category search results
      const maintainOtherVendorResults =
        obscureSearchResultSet === OBSCURE_SEARCH_RESULT_SETS.PRIMARY_RESULTS;
      if (!isempty(relatedVendorResults) && !maintainOtherVendorResults) {
        result.otherVendorsResults = {
          vendors: relatedVendorResults,
          total: relatedVendorTotalResults,
        };
      }

      yield put({
        type: SEARCH_SUCCESS,
        ...result,
        searchInfo: {
          ...searchInfo,
          isSearchById,
          version,
        },
        searchCopy,
        searchTerm,
        maintainOtherVendorResults,
        stateCityLinks,
      });
    }
  } catch (error) {
    yield put({
      type: SEARCH_FAILURE,
      error,
      searchTerm,
    });
  }
}

export function* searchSaga() {
  yield all([takeEvery(SEARCH_REQUEST, searchAsync)]);
  yield all([takeEvery(SEARCH_LOAD_MORE_REQUEST, loadMoreAsync)]);
}
