import Head from 'next/head';
import React, { useEffect } from 'react';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';

import { Provider } from 'react-redux';

import {
  GlobalStyles as SharedGlobalStyles,
  LinkProvider,
  muiTheme,
} from '@tkww/the-bash-frontend';

import GlobalStyles from 'styles/global';

import { gmConfig } from 'helpers/config';
import createEmotionCache from 'helpers/createEmotionCache';

// Keep this API import below Nav and MUI Page Context, there are strange issues
// happening if this is imported before. One of the issues is, when the api is imported within our
// redux saga definition's file, sessions object was undefined, making our session's api methods unavailable
// and breaking the application at loading time.

import configureStore from 'state/configureStore';

import PageView from 'components/PageView';
import * as nprogress from 'nprogress';
import { ModalProvider, Modal } from 'state/context/ModalProvider';
import { calculateResponsiveState } from 'redux-responsive';

import { sendToGTM } from 'helpers/googleTagManager';
import { CssBaseline } from '@mui/material';

import StaticNav from 'views/StaticHeader';
import Header from 'views/Header';

const clientSideEmotionCache = createEmotionCache();

const onRouteChangeStart = () => {
  // for performance tracking
  // https://www.pivotaltracker.com/n/projects/2140033/stories/158981498
  window.performance.clearMarks('start navigation');
  window.performance.mark('start navigation');

  nprogress.start();
};

const stopLoader = () => {
  setTimeout(() => {
    nprogress.done();
  }, 500);
};

// when internal navigation changed completely
const onRouteChangeComplete = () => {
  window.scrollTo(0, 0);
  stopLoader();
};

const onRouteChangeError = () => {
  stopLoader();
};

const StaticFooter = dynamic(() => import('views/StaticFooter'));
const Footer = dynamic(() => import('views/Footer'));

const navMethod = (as, href) => {
  if (href) {
    Router.push(href, as);
  } else {
    Router.push(as);
  }
};

export function reportWebVitals({ id, name, label, value }) {
  const eventCategory = label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric';
  sendToGTM(id, name, eventCategory, value);
}

const TheBashApp = ({ Component, ...rest }) => {
  const { store, props } = configureStore.useWrappedStore(rest);
  const { pageProps, router, isAmp, emotionCache = clientSideEmotionCache } = props;

  const { widget } = router.query;
  const AppHeader = widget ? StaticNav : Header;
  const AppFooter = widget ? StaticFooter : Footer;

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.events.on('routeChangeError', onRouteChangeError);
    store.dispatch(calculateResponsiveState(window));
  }, []);

  return (
    <>
      <Head>
        <title>The Bash</title>
        {!isAmp && (
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        )}
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <GlobalStyles />
          <SharedGlobalStyles />
          <Provider store={store}>
            <LinkProvider wwwDomain={gmConfig.domains.www} stack="node" navMethod={navMethod}>
              <ModalProvider>
                <AppHeader />
                <main>
                  <PageView {...pageProps}>
                    <Component {...pageProps} />
                  </PageView>
                </main>
                <AppFooter />
                <Modal />
              </ModalProvider>
            </LinkProvider>
          </Provider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default TheBashApp;
