import cookie from 'js-cookie';
import { gmConfig } from 'helpers/config';
import isEmpty from 'lodash.isempty';

const cookieHelper = {
  get: (key) => cookie.get(`${gmConfig.cookie.prefix}${key}`),
  getServerSide: (req, key) => {
    if (isEmpty(req.headers.cookie)) return '';
    const cookieObj = req.headers.cookie.split('; ').reduce((prev, current) => {
      const [name, ...value] = current.split('=');
      // eslint-disable-next-line no-param-reassign
      prev[name] = value.join('=');
      return prev;
    }, {});
    return cookieObj[`${gmConfig.cookie.prefix}${key}`];
  },
  remove: (key, hasPrefix = true) => {
    const { prefix, domain } = gmConfig.cookie;
    cookie.remove(hasPrefix ? `${prefix}${key}` : key, { domain });
  },
  set: (name, value, options = {}) => {
    const { prefix, domain } = gmConfig.cookie;
    cookie.set(`${prefix}${name}`, value, {
      domain,
      ...options,
    });
  },
};

export default cookieHelper;
