import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { pageViewEvent, setPageLevelBaseProperties } from '@tkww/the-bash-frontend';
import { setExperiment, unsetExperiment } from 'helpers/experimentHelpers';
import { useAmp } from 'next/amp';
import { segmentKey } from 'helpers/config';

const PageView = ({
  pageName,
  pageViewProps = {},
  pageLevelBaseProps,
  experiment,
  children,
  canonicalPageUrl,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (children.props.errorCode) return;
    // eslint-disable-next-line no-unused-expressions
    window.analytics &&
      window.analytics.ready(() => {
        if (experiment) setExperiment(experiment);

        dispatch(
          pageViewEvent(pageName, {
            ...pageViewProps,
            ...pageLevelBaseProps,
          })
        );

        if (!experiment) unsetExperiment();
      });

    dispatch(setPageLevelBaseProperties(pageLevelBaseProps || {}));
  });

  const isAmp = useAmp();

  const ampProperties = pageViewProps
    ? {
        extraUrlParams: Object.entries(pageViewProps).reduce((accum, [name, value]) => {
          // eslint-disable-next-line no-param-reassign
          accum[`properties.${name}`] = value;
          return accum;
        }, {}),
      }
    : {};

  return (
    <>
      {children}
      {isAmp && (
        <amp-analytics type="segment">
          <script
            data-testid="amp-analytics-script"
            type="application/json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                vars: {
                  writeKey: segmentKey,
                  name: pageName,
                  canonicalUrl: canonicalPageUrl,
                },
                ...ampProperties,
              }),
            }}
          />
        </amp-analytics>
      )}
    </>
  );
};

PageView.propTypes = {
  children: PropTypes.element.isRequired,
  pageName: PropTypes.string,
};

export default PageView;
