import 'isomorphic-fetch';

import { apiUrl, gmApiUrl } from 'helpers/config';

const defaultOptions = {
  credentials: 'include',
  method: 'GET',
  headers: {},
};

function baseRequest(url, path, options, isJSON) {
  const combinedOptions = { ...defaultOptions, ...options };

  if (isJSON) combinedOptions.headers['Content-Type'] = 'application/json';

  return fetch(`${url}${path}`, {
    ...combinedOptions,
  }).then((res) => {
    const json = res.text().then((text) => (text ? JSON.parse(text) : {}));
    return res.ok ? json : json.then(Promise.reject.bind(Promise));
  });
}

export function request(path, options = {}, isJSON = true) {
  return baseRequest(apiUrl, path, options, isJSON);
}

export function gmRequest(path, options = {}, isJSON = true) {
  return baseRequest(gmApiUrl, path, { ...options, credentials: 'omit' }, isJSON);
}
