import { ERROR, LOADED, LOADING } from 'state/status';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { fetchAllVenueMarkets } from 'api/venues';
import { HYDRATE } from 'next-redux-wrapper';
import mergeState from 'state/utils/mergeState';

export const GET_ALL_VENUE_MARKETS_REQUEST = 'the-bash/venues/GET_ALL_VENUE_MARKETS_REQUEST';
export const GET_ALL_VENUE_MARKETS_SUCCESS = 'the-bash/venues/GET_ALL_VENUE_MARKETS_SUCCESS';
export const GET_ALL_VENUE_MARKETS_FAILURE = 'the-bash/venues/GET_ALL_VENUE_MARKETS_FAILURE';
export const ALL_VENUE_MARKETS = 'ALL_VENUE_MARKETS';

const initialState = {
  fetchStatus: {},
  entities: {},
  errors: {},
};

const venuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      const { venueMarkets = initialState } = action.payload;
      return mergeState(initialState, state, venueMarkets);
    case GET_ALL_VENUE_MARKETS_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [ALL_VENUE_MARKETS]: LOADING,
        },
      };
    }
    case GET_ALL_VENUE_MARKETS_SUCCESS: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [ALL_VENUE_MARKETS]: LOADED,
        },
        entities: {
          ...state.entities,
          [ALL_VENUE_MARKETS]: action.venueMarkets,
        },
      };
    }
    case GET_ALL_VENUE_MARKETS_FAILURE: {
      return {
        ...state,
        fetchStatus: {
          ...state.fetchStatus,
          [ALL_VENUE_MARKETS]: ERROR,
        },
        errors: {
          ...state.errors,
          [ALL_VENUE_MARKETS]: action.error,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default venuesReducer;

export const getAllVenueMarkets = () => ({ type: GET_ALL_VENUE_MARKETS_REQUEST });

export const selectAllVenueMarketsLoaded = createSelector(
  (state) => state.venueMarkets.fetchStatus,
  (_, query) => query,
  (fetchStatus, query) => fetchStatus[query] === LOADED
);
export const selectAllVenueMarketsHasError = createSelector(
  (state) => state.venueMarkets.fetchStatus,
  (_, query) => query,
  (fetchStatus, query) => fetchStatus[query] === ERROR
);
export const selectAllVenueMarketsErrorContent = createSelector(
  (state) => state.venueMarkets.errors,
  (_, query) => query,
  (errors, query) => errors[query]
);

export const selectAllVenueMarkets = createSelector(
  (state) => state.venueMarkets.entities,
  (_, query) => query,
  (entities, query) => entities[query] || []
);

export function* getAllVenueMarketsAsync() {
  try {
    const venueMarkets = yield call(fetchAllVenueMarkets);
    yield put({ type: GET_ALL_VENUE_MARKETS_SUCCESS, venueMarkets });
  } catch (error) {
    yield put({ type: GET_ALL_VENUE_MARKETS_FAILURE, error });
  }
}

export function* venuesSaga() {
  yield all([takeLatest(GET_ALL_VENUE_MARKETS_REQUEST, getAllVenueMarketsAsync)]);
}
